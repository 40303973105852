import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Errors      from '../components/errors/errors'
import Page        from '../templates/page'
import { ColourData }               from '@open-law/open-law-shared'

export const query = graphql`

    query FrontpageQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
            ...PageInfo
        }
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            title
            openGraph {
                title
                description
                image {
                    ...SanityImage
                }
            }
        }
    }
`

interface IndexPageProps {
    data: {
        page: {
            id: string
            navMenu: unknown
            title: string
            _rawContent: unknown
        }
        site: {
            openGraph: unknown
            primaryColor: ColourData
            secondaryColor: ColourData
            title: string
        }
    }
    errors: any
}

const IndexPage: FunctionComponent<IndexPageProps> = (props) => {
    const { data, errors } = props

    if (errors) {
        return <Errors errors={errors} />
    }

    return <Page data={data} />
}

export default IndexPage
